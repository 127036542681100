import { render, staticRenderFns } from "./Error.vue?vue&type=template&id=d2dc2a88&scoped=true&"
import script from "./Error.vue?vue&type=script&lang=js&"
export * from "./Error.vue?vue&type=script&lang=js&"
import style0 from "./Error.vue?vue&type=style&index=0&id=d2dc2a88&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d2dc2a88",
  null
  
)

export default component.exports