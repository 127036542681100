<template>
  <AppCard>
    <template #header>
      <AppCardHeader v-if="mq.current !== 'xs'">
        <template v-if="showBackArrow && checkLayout('register')" #left>
          <AppButton theme="icon" @click="onRouterBack()">
            <AppIcon name="Go Back">
              <IconChevronLeft />
            </AppIcon>
          </AppButton>
        </template>
        <div class="app-icon">
          <span role="img" aria-labelledby="Error">&#10060;</span>
        </div>
      </AppCardHeader>
    </template>
    <div class="text-center">
      <template v-if="mq.current == 'xs'">
        <div class="app-icon pb-5">
          <span role="img" aria-labelledby="Error">&#10060;</span>
        </div>
      </template>
      <h1 class="mb-4 type-h1">{{ titleText }}</h1>
      <!-- Added line break following the figma design - General error -->
      <p v-if="errorType === 'general'" class="pb-6 text-gray type-subtitle">
        {{ paragraphText.split('.')[0] }}.<br />
        {{ paragraphText.split('.')[1] }}.
      </p>
      <!-- other error types -->
      <p v-else class="pb-6 text-gray type-subtitle">
        {{ paragraphText }}
      </p>
    </div>
    <AppCardFooter>
      <AppButton class="try-again" @click="onClick">
        {{ buttonText }}
      </AppButton>
      <AppButton class="contact-us" theme="text" @click="onContactUs">Contact us</AppButton>
    </AppCardFooter>
  </AppCard>
</template>

<script>
import { reactive } from '@vue/composition-api'
import { useRouter } from '@/composables/useRouter'
import { useMediaQuery } from '@/composables/useMediaQuery'

import AppButton from '@/components/AppButton/AppButton'
import AppCard from '@/components/AppCard/AppCard'
import AppCardHeader from '@/components/AppCardHeader/AppCardHeader'
import AppCardFooter from '@/components/AppCardFooter/AppCardFooter'
import AppIcon from '@/components/AppIcon/AppIcon'

import { IconChevronLeft } from '@moneytransfer.ui/euronet-icons'
import { useCorporateRegistrationStore } from '@/stores/corporateRegistration'
import { useAuthStore } from '../stores/auth'

// Raw data to render depending of the type error
export const errorTypeData = Object.freeze({
  general: {
    titleText: 'Something went wrong!',
    paragraphText: 'Sorry, there’s an unexpected error. Please try again later.',
    buttonText: 'Try again',
    stepId: -1,
    showBackArrow: false,
  },
  duplicate: {
    titleText: 'You’re already registered',
    paragraphText: 'Looks like your company already has an account. Try to login instead.',
    buttonText: 'Login',
    stepId: 0,
    showBackArrow: true,
  },
  natureOfBusiness: {
    titleText: 'We can’t finish your registration',
    paragraphText: 'Sorry, we’re unable to serve the industry you’ve selected.',
    buttonText: 'Okay',
    stepId: 0,
    showBackArrow: true,
  },
  dashboard: {
    titleText: 'Something went wrong!',
    paragraphText: 'Sorry, there’s an unexpected error. Please try again later.',
    buttonText: 'Try again',
    stepId: -1,
    showBackArrow: false,
  },
})

export default {
  name: 'RegisterError',
  components: {
    AppButton,
    AppCard,
    AppCardHeader,
    AppCardFooter,
    AppIcon,
    IconChevronLeft,
  },
  props: {
    errorType: {
      type: String,
      default: 'general',
      validator: (value) => {
        return Object.prototype.hasOwnProperty.call(errorTypeData, value)
      },
    },
    layout: {
      type: String,
      default: 'registration',
    },
    isFullReload: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const router = useRouter()
    const authStore = useAuthStore()

    const errorName = Object.keys(errorTypeData)
    const errorType =
      errorTypeData[errorName.includes(props.errorType) ? props.errorType : 'general']
    const showBackArrow = errorType.showBackArrow
    const titleText = errorType.titleText
    const paragraphText = errorType.paragraphText
    const buttonText = errorType.buttonText
    const stepId = errorType.stepId

    const corporateRegistrationStore = useCorporateRegistrationStore()

    corporateRegistrationStore.activeStepIdx = stepId
    corporateRegistrationStore.setActiveStepPageTitle(props.errorType)

    const checkLayout = (layout) => {
      return props.layout === layout
    }

    const onRouterPush = (route) => {
      // remove "leave site" listener
      window.removeEventListener('beforeunload', window.handleWindowClose)
      // Pushing to...
      router.push(route)
    }

    const onRouterBack = () => {
      window.removeEventListener('beforeunload', window.handleWindowClose)
      router.back()
    }

    const onClick = () => {
      if (props.errorType === 'duplicate') {
        window.location.href = authStore.loginUrl
      } else if (props.errorType === 'natureOfBusiness') {
        window.location = 'https://www.xe.com/mt/ca-business/'
      } else if (props.errorType === 'general' || props.errorType === '') {
        window.removeEventListener('beforeunload', window.handleWindowClose)
        router.push({ name: 'home' }).then(() => router.go())
      } else if (props.errorType === 'dashboard') {
        onRouterPush({ name: 'PendingActions' })
        if (props.isFullReload) {
          window.location.reload()
        }
      }
    }

    const onContactUs = () => {
      window.location = 'https://www.xe.com/contact/faq/'
    }

    return {
      onRouterBack,
      showBackArrow,
      buttonText,
      onClick,
      onContactUs,
      onRouterPush,
      checkLayout,
      paragraphText,
      titleText,
      mq: reactive(useMediaQuery()),
    }
  },
}
</script>

<style lang="postcss" scoped>
.app-icon {
  @apply mt-6;

  span {
    @apply text-2xl;
  }
}

.card-footer {
  @apply flex-col pt-0;
  @apply shadow-none;

  @screen xs {
    @apply mx-6 mb-6;

    .button {
      border-radius: 0.5rem;
    }
  }

  .contact-us {
    @apply text-base mt-6;
  }
}
</style>
